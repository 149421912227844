import axios from 'axios';

const ax = axios.create({
    baseURL: 'https://'+location.hostname+'/'
});

const button_action = document.getElementById('button-action')
const button_back = document.getElementById('button-back')

const back_field = document.getElementsByClassName('back-field')[0]
const email_field = document.getElementsByClassName('email-field')[0]
const password_field = document.getElementsByClassName('password-field')[0]

const email = document.getElementById('email')
const password = document.getElementById('password')

let form_step = 1;

document.addEventListener('DOMContentLoaded', ev => button_action.disabled = (email.value == undefined || email.value == ""))
email.addEventListener('input', ev => button_action.disabled = (email.value == undefined || email.value == ""))
email.addEventListener('keyup', ev => {
    if (ev.key == "Enter") {
        button_action.click()
    }
})
password.addEventListener('keyup', ev => {
    if (ev.key == "Enter") {
        button_action.click()
    }
})

button_back.addEventListener('click', (ev) => {
    form_step = 1
    goToStep(form_step)
})

button_action.addEventListener('click', (ev) => {
    ev.preventDefault()
    let validation = false;
    document.getElementById('password-error').innerText = ""
    document.getElementById('email-error').innerText = ""

    switch (form_step) {
        case 1:
            validation = validateEmail()
            break;
        case 2:
            validation = validatePassword()
            break;
    }

    if(validation)
    {
        form_step++
        goToStep(form_step)        
    } else {
        if (form_step == 1) {
            document.getElementById('email-error').innerText = "Invalid email address"
        } else {
            document.getElementById('password-error').innerText = "Invalid password"
        }
    }

    if(form_step == 3)
    {
        ax.post('login', {
            email: email.value,
            password: password.value
        }).then((response) => {
            if (!response.data.login && response.data.error != null) {
                form_step--
                password.value = ""
                document.getElementById('password-error').innerText = response.data.error
                goToStep(form_step)
            } else {
                window.location.href = 'https://'+location.hostname
            }
        })
    }
    
})

function validateEmail()
{
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email.value).toLowerCase())
}

function validatePassword()
{
    return String(password.value).length > 6
}

function goToStep(step)
{
    switch (step) {
        case 1:
            email_field.classList.remove('animate-out')
            email_field.classList.add('animate-in')
            
            password_field.classList.remove('animate-in')
            password_field.classList.add('animate-out')

            back_field.classList.remove('animate-in')
            back_field.classList.add('animate-out')

            button_action.innerText = "NEXT"
            
            email.focus()
        break;

        case 2:
            email_field.classList.remove('animate-in')
            email_field.classList.add('animate-out')
            
            password_field.classList.remove('animate-out')
            password_field.classList.add('animate-in')
            
            back_field.classList.remove('animate-out')
            back_field.classList.add('animate-in')

            button_action.disabled = false
            button_action.classList.remove('is-loading')

            button_action.innerText = "LOGIN"
            password.focus()
        break;

        case 3:
            password.blur()
            button_action.disabled = true
            button_action.classList.add('is-loading')
            back_field.classList.remove('animate-in')
            back_field.classList.add('animate-out')
        break;
    }
}